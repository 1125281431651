import { render, staticRenderFns } from "./PaymentMethodsTab.vue?vue&type=template&id=24171106&scoped=true&"
import script from "./PaymentMethodsTab.vue?vue&type=script&lang=js&"
export * from "./PaymentMethodsTab.vue?vue&type=script&lang=js&"
import style0 from "./PaymentMethodsTab.vue?vue&type=style&index=0&id=24171106&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24171106",
  null
  
)

export default component.exports